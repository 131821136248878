<template>
  <div class="flex flex-col gap-y-2">
    <label
      :for="name"
      class="text-button-small text-white opacity-[0.87] ltr:tracking-[0.6px] rtl:tracking-normal"
    >
      {{ label }}
    </label>
    <div
      :class="[
        { 'bg-white/[0.15]': inputValue?.trim().length > 0 },
        {
          '!border-brand-red-error': meta.touched && !meta.valid,
        },
        focused ? 'focus:border-white/80' : 'border-transparent',
      ]"
      class="rounded bg-white/10 hover:bg-white/[0.15] flex items-center border"
    >
      <input
        :name="name"
        :placeholder="placeholder"
        :value="inputValue"
        :type="inputType"
        autocomplete="off"
        class="w-full rounded py-[12px] ps-6 bg-transparent text-body-big text-white opacity-[0.87] outline-none placeholder-white disabled:text-white disabled:opacity-50"
        @input="handleChange"
        @blur="onBlur"
        @focus="focused = true"
        :class="{ 'plain-text': isRTL }"
      />
      <button
        v-if="inputValue?.trim().length > 0"
        class="w-12 h-full flex justify-center items-center outline-none"
        tabindex="-1"
        @click.prevent="reveal = !reveal"
        type="button"
      >
        <Icon v-if="reveal" name="IconEyeOn" size="24"></Icon>
        <Icon v-else name="IconEyeOff" size="24"></Icon>
      </button>
    </div>

    <span
      v-if="showErrorMessage && meta.touched && !meta.valid"
      class="text-red-700"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";
const { isRTL } = useCurrentLocale();
const props = defineProps({
  name: String,
  label: String, // should be i18nized
  placeholder: String, // should be i18nized
  showErrorMessage: {
    type: Boolean,
    default: () => true,
  },
});

const focused = ref(false);

const name = toRef(props, "name");

const reveal = ref(false);

const iconName = computed(() => {
  return reveal.value ? "IconEyeOn" : "IconEyeOff";
});

const inputType = computed(() => {
  return reveal.value ? "text" : "password";
});

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
  resetField,
} = useField(name, undefined);

const onBlur = (e) => {
  focused.value = false;
  handleBlur(e);
};
</script>

<style lang="scss" scoped></style>
